var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-settings"},[(_vm.space)?_c('StatusLabel',{attrs:{"page":"space","status":_vm.space.is_active ? 'active' : 'inactive'}}):_vm._e(),(_vm.space)?_c('div',{staticClass:"space-container"},[_c('div',{staticClass:"side column is-4"},[_c('BackButton'),(_vm.space.venue)?_c('div',{staticClass:"venue-card"},[(_vm.space.venue.venue_images.length)?_c('img',{attrs:{"src":((_vm.space.venue.venue_images[0].image_url) + "?tr=h-52"),"alt":"venue img","loading":"lazy","width":"83","height":"52"}}):_vm._e(),_c('div',{staticClass:"venue-name"},[_vm._v(_vm._s(_vm.space.venue.venue_name))])]):_vm._e(),_c('ul',{staticClass:"menu"},_vm._l((_vm.PAGES),function(item){return _c('li',{key:item,staticClass:"menu-item"},[_c('router-link',{staticClass:"menu-item-link",class:[
              'menu-item-link',
              {
                'router-link-exact-active': _vm.$route.name.includes(
                  _vm.state.ROUTE_LIST_NAMES.SPACE_SETTINGS[item]
                )
              }
            ],attrs:{"to":{ name: _vm.state.ROUTE_LIST_NAMES.SPACE_SETTINGS[item] },"exact":""}},[(
                _vm.$route.name.includes(
                  _vm.state.ROUTE_LIST_NAMES.SPACE_SETTINGS[item]
                )
              )?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs",staticStyle:{"position":"absolute","left":"0","font-size":"18px"}}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t(("space.side_menu.label_" + (item.toLowerCase()) + "_link"))))])])],1)}),0),(
          _vm.space &&
          _vm.$route.name.includes(_vm.state.ROUTE_LIST_NAMES.SPACE_SETTINGS.DETAILS)
        )?_c('SideActions',{attrs:{"is-active":_vm.space.is_active,"slug":_vm.space.space_url,"page":'space'},on:{"onTrash":function($event){_vm.state.deleteConfirmationVisible = true},"onDeactivate":function($event){_vm.state.deactivateConfirmationVisible = true},"onActivate":_vm.handleSpaceActivation,"open-share":function($event){_vm.isShareOpen = true}}}):_vm._e()],1),_c('router-view',{ref:"spaceChild",staticClass:"space-pages column is-8",attrs:{"revert":_vm.state.revert,"on-save":_vm.state.isUpdatedSuccessfully},on:{"settingsChanged":_vm.settingsChanged,"updateSpaceDetails":_vm.updateSpaceDetails,"noChanges":_vm.noChanges}})],1):_vm._e(),(_vm.state.deleteConfirmationVisible)?_c('DeleteConfirmation',{attrs:{"action-button-text":_vm.$t('components.delete_confirmation.button_label_remove').toString(),"description":_vm.$t('components.delete_confirmation.label_description').toString()},on:{"remove":_vm.removeSpace,"close":function($event){_vm.state.deleteConfirmationVisible = false}}}):_vm._e(),(_vm.state.deactivateConfirmationVisible)?_c('DeleteConfirmation',{attrs:{"action-button-text":_vm.$tc('space.activation_button', +_vm.state.deactivateConfirmationVisible),"action-button-type":("" + (_vm.space.is_active ? 'danger' : 'primary')),"action-button-icon-class":("fa-solid fa-toggle-" + (_vm.space.is_active ? 'off' : 'on') + " fa-sm"),"description":_vm.$t('components.deactivate_confirmation.label_description').toString()},on:{"remove":_vm.changeActive,"close":function($event){_vm.state.deactivateConfirmationVisible = false}}}):_vm._e(),(
      _vm.state.activateConfirmationVisible &&
      (_vm.hasSynchronizedCalendars || _vm.hasSpaceActivationPermission)
    )?_c('DeleteConfirmation',{attrs:{"action-button-text":_vm.$tc('space.activation_button', +!_vm.state.activateConfirmationVisible),"action-button-type":("" + (_vm.space.is_active ? 'danger' : 'primary')),"action-button-icon-class":("fa-solid fa-toggle-" + (_vm.space.is_active ? 'off' : 'on') + " fa-sm"),"description":_vm.$t('components.activate_confirmation.label_description').toString()},on:{"remove":_vm.changeActive,"close":function($event){_vm.state.activateConfirmationVisible = false}}}):_vm._e(),(
      _vm.state.activateConfirmationVisible &&
      !_vm.hasSynchronizedCalendars &&
      !_vm.hasSpaceActivationPermission
    )?_c('AgendaSynchModal',{on:{"agenda":_vm.goToAgendaSync,"close":function($event){_vm.state.activateConfirmationVisible = false}}}):_vm._e(),(_vm.isShareOpen)?_c('SocialSharing',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isShareOpen),expression:"isShareOpen"}],attrs:{"data":_vm.space},on:{"close":function($event){_vm.isShareOpen = false}}}):_vm._e(),_c('PreSaveActionModal',{attrs:{"action-type":_vm.unfinishedActionType},on:{"continuePreSave":_vm.continuePreSaveAction,"cancelPreSave":_vm.cancelPreSaveSpaceAction},model:{value:(_vm.hasUnfinishedAction),callback:function ($$v) {_vm.hasUnfinishedAction=$$v},expression:"hasUnfinishedAction"}}),(_vm.space)?_c('SaveFooter',{attrs:{"is-changed":_vm.hasChanges,"on-save":_vm.onSaveChanges,"on-close":_vm.onCloseSaveFooter,"is-saved":_vm.isChangesSaved,"on-revert":_vm.onRevertChanges,"page":'space'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }