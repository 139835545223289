import { defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
export default defineComponent({
    setup(_, context) {
        const { emit } = context;
        const closePopup = () => {
            emit('close');
        };
        const goToAgenda = () => {
            emit('close');
            emit('agenda');
        };
        return {
            HelpdeskRoutes,
            closePopup,
            goToAgenda,
            useGetLocalizedPath
        };
    }
});
