import { reactive, computed, defineComponent, ref, onMounted } from '@vue/composition-api';
import { pickBy, keys, isEqual, isEmpty, difference } from 'lodash';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import BackButton from '@/components/BackButton.vue';
import AgendaSynchModal from '@/shared/components/AgendaSynchModal.vue';
import DeleteConfirmation from '@/shared/components/DeleteConfirmation.vue';
import SideActions from '@/shared/components/SideActions.vue';
import StatusLabel from '@/shared/components/StatusLabel.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import SocialSharing from '@/Space/components/SocialSharing.vue';
import { checkRemovedAndNewImages, saveCurrentPath, snakeToCamel } from '@/util/utils';
import { withAuthMutationHook } from '@/util/hooks';
import { useAddFeaturesMutation, useUpdateSpaceDetailsMutation, useRemoveSpaceMutation, useAddImagesMutation, useUpdateImagesListMutation, useGetSpaceDetailsQuery, useUpdateConfigurationsMutation, useAddConfigurationsMutation, useUpdateFacilitiesMutation, useAddFacilitiesMutation, UserRoleState, UserEventPermission } from '@/generated-types/graphql.types';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import PreSaveActionModal from '@/shared/components/PreSaveActionModal.vue';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { ServerErrors } from '@/util/graphql';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { BookingRoutes } from '@/Booking/booking.const';
const addFeatures = withAuthMutationHook(useAddFeaturesMutation);
const deleteSpace = withAuthMutationHook(useRemoveSpaceMutation);
const updateSpace = withAuthMutationHook(useUpdateSpaceDetailsMutation);
const createImages = withAuthMutationHook(useAddImagesMutation);
const updateImages = withAuthMutationHook(useUpdateImagesListMutation);
const updateConfigurations = withAuthMutationHook(useUpdateConfigurationsMutation);
const addConfigurations = withAuthMutationHook(useAddConfigurationsMutation);
const addFacilities = withAuthMutationHook(useAddFacilitiesMutation);
const updateFacilities = withAuthMutationHook(useUpdateFacilitiesMutation);
import EventBus from '@/shared/services/eventBus';
const PAGES = [
    'DETAILS',
    'CONFIGURATIONS',
    'BOOKING_RULES',
    'FACILITIES',
    'PRICING',
    'FEATURES'
];
const labelId = Number(process.env.VUE_APP_LABEL_ID);
export default defineComponent({
    components: {
        BackButton,
        AgendaSynchModal,
        DeleteConfirmation,
        SideActions,
        StatusLabel,
        SaveFooter,
        SocialSharing,
        PreSaveActionModal
    },
    setup(_, context) {
        const { root } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const hasSpaceActivationPermission = computed(() => user.value?.event_permissions[UserEventPermission.AllowedToActivateSpacesWithoutSync] || false);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const spaceChild = ref(null);
        const state = reactive({
            ROUTE_LIST_NAMES,
            deleteConfirmationVisible: false,
            deactivateConfirmationVisible: false,
            activateConfirmationVisible: false,
            venue: null,
            isSettingsChanged: false,
            isUpdatedSuccessfully: false,
            page: '',
            revert: false,
            formData: {
                details: {
                    images: []
                },
                features: {},
                configurations: []
            },
            prevData: {
                details: {
                    images: []
                },
                features: {},
                configurations: []
            },
            spaceDetails: {}
        });
        const hasUnfinishedAction = ref(false);
        const unfinishedActionType = ref('');
        const isShareOpen = ref(false);
        const { spaceId } = root.$route.params;
        const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
        const { result: space, refetch: refetchSpace } = useGetSpaceDetailsQuery({ space_id: +spaceId }, { fetchPolicy: 'no-cache' });
        const hasSynchronizedCalendars = computed(() => {
            const spaceActiveCalendars = space?.value?.specific_space?.space_calendars?.filter(calendar => calendar?.is_synchronized);
            return !!spaceActiveCalendars && spaceActiveCalendars.length > 0;
        });
        const { mutate: updateConfigurationsMutation } = updateConfigurations({
            clientId: 'legacy'
        });
        const { mutate: createConfigurationsMutation } = addConfigurations({
            clientId: 'legacy'
        });
        const { mutate: updateFacilitiesMutation } = updateFacilities({
            clientId: 'legacy'
        });
        const { mutate: createFacilitiesMutation } = addFacilities({
            clientId: 'legacy'
        });
        const { mutate: createFeaturesMutation } = addFeatures();
        const { mutate: updateSpaceMutation } = updateSpace({ clientId: 'legacy' });
        const { mutate: deleteSpaceMutation } = deleteSpace({ clientId: 'legacy' });
        const { mutate: updateImagesMutation } = updateImages({
            clientId: 'legacy'
        });
        const { mutate: createImagesMutation } = createImages();
        const back = () => {
            globalThis.$router.push({ name: state.ROUTE_LIST_NAMES.SPACES });
        };
        const settingsChanged = (payload) => {
            const arr = root.$route.name.split('-');
            const { page, data, prevData, extra } = payload;
            if (page !== arr[arr.length - 1])
                return;
            state.isUpdatedSuccessfully = false;
            if (prevData &&
                Object.prototype.hasOwnProperty.call(prevData, 'images')) {
                prevData.images = prevData.images.map(image => image.image_id || image);
            }
            const isChanged = !isEqual(data, prevData);
            if (isChanged) {
                state.formData[page] = extra
                    ? { ...data.buildSendData(), images: extra }
                    : data;
                state.prevData[page] = prevData;
                state.page = page;
                state.revert = false;
            }
            state.isSettingsChanged = isChanged;
        };
        const updateSpaceDetails = payload => {
            state.revert = false;
            state.isUpdatedSuccessfully = false;
            state.isSettingsChanged = true;
            state.spaceDetails = payload.data;
        };
        const noChanges = () => {
            state.isSettingsChanged = false;
        };
        const cleaning = () => {
            state.prevData = {
                details: {
                    images: []
                },
                features: {},
                configurations: []
            };
            state.formData = {
                details: {
                    images: []
                },
                features: {},
                configurations: []
            };
            state.page = '';
            state.spaceDetails = {};
        };
        const onSaveChanges = () => {
            const errs = spaceChild?.value?.checkErrors(true);
            if (errs.length)
                return;
            if (!isEmpty(state.spaceDetails)) {
                updateSpaceWithInput(state.spaceDetails)
                    .then(res => {
                    if (!state.page) {
                        state.isUpdatedSuccessfully = !!res;
                        if (res)
                            cleaning();
                        return;
                    }
                })
                    .catch(error => {
                    console.error(error);
                    return;
                });
            }
            if (state.page) {
                updateFunctions[state.page]()
                    .then(res => {
                    state.isUpdatedSuccessfully = !!res;
                    if (res)
                        cleaning();
                })
                    .catch(error => {
                    console.error(error);
                });
            }
            EventBus.$emit('closeActivitiesModal');
        };
        const onCloseSaveFooter = () => {
            state.isSettingsChanged = false;
            state.isUpdatedSuccessfully = false;
        };
        const hasChanges = () => {
            return state.isSettingsChanged;
        };
        const isChangesSaved = () => {
            return state.isUpdatedSuccessfully;
        };
        const onRevertChanges = () => {
            state.revert = true;
            state.isSettingsChanged = false;
            cleaning();
            EventBus.$emit('closeActivitiesModal');
        };
        // Updating details
        const detailsUpdate = () => {
            const ids = checkRemovedAndNewImages(state.prevData.details.images, state.formData.details.images);
            const oldIds = state.prevData?.details?.images?.map(x => x.image_id || x);
            const newItems = state.formData?.details?.images?.map(x => x.image_id || x);
            if (!ids['newIds'].length && !ids['removedIds'].length) {
                if (!isEqual(oldIds, newItems)) {
                    return updateImagesMutation({
                        input: {
                            imagesToDeleteIds: ids['removedIds'],
                            imagesIds: state.formData.details.images
                        },
                        spaceId: +spaceId
                    }, {})
                        .then(() => {
                        return updateDetails();
                    })
                        .catch(error => {
                        console.error(error);
                    });
                }
                else {
                    return updateDetails();
                }
            }
            if (ids['newIds'].length && !ids['removedIds'].length) {
                return createImagesMutation({ images_ids: ids['newIds'], space_id: +spaceId }, {})
                    .then(() => {
                    return updateDetails();
                })
                    .catch(error => {
                    console.error(error);
                });
            }
            if (ids['removedIds'].length) {
                return updateImagesMutation({
                    input: {
                        imagesToDeleteIds: ids['removedIds'],
                        imagesIds: state.formData.details.images
                    },
                    spaceId: +spaceId
                }, {})
                    .then(() => {
                    return updateDetails();
                })
                    .catch(error => {
                    console.error(error);
                });
            }
            function updateDetails() {
                delete state.formData?.details?.images;
                delete state.formData?.details?.venue_id;
                const _input = snakeToCamel(state.formData?.details);
                delete _input?.isActive;
                return updateSpaceWithInput(_input);
            }
        };
        // Updating features
        const featuresUpdate = () => {
            const _input = {
                spaceId: +spaceId,
                featureIds: keys(pickBy(state.formData.features)).map(Number)
            };
            return createFeaturesMutation({ feature_ids: _input.featureIds, space_id: +spaceId }, {});
        };
        // Updating configurations
        const configurationsUpdate = () => {
            function generateRequestData(arr) {
                return arr.map(el => {
                    const price = el.configuration_price || el.price;
                    return {
                        configurationId: el.configuration_id,
                        configurationPrice: price || 0,
                        maxGuests: el.attendees,
                        minGuests: 0,
                        isDefault: !el.custom
                    };
                });
            }
            const checkedConfigurations = state.formData.configurations.filter(el => el.checked);
            const previouselyChecked = state.prevData.configurations.filter(el => el.checked);
            const configurationIdsToDelete = previouselyChecked
                .filter(el => !checkedConfigurations.find(item => el.configuration_id === item.configuration_id))
                .map(el => el.configuration_id);
            const newConfigurations = checkedConfigurations.filter(el => state.prevData.configurations.find(item => el.configuration_id === item.configuration_id && !item.checked));
            const updatedConfigurations = difference(checkedConfigurations, newConfigurations);
            const _input = {
                update: {
                    configurationIdsToDelete,
                    configurations: generateRequestData(updatedConfigurations)
                },
                create: {
                    labelId: labelId,
                    configurations: generateRequestData(newConfigurations)
                }
            };
            function update() {
                return updateConfigurationsMutation({ spaceId: +spaceId, input: _input.update }, {});
            }
            function create() {
                return createConfigurationsMutation({
                    input: _input.create,
                    spaceId: +spaceId
                }, {});
            }
            if (newConfigurations.length &&
                !(configurationIdsToDelete.length || updatedConfigurations.length)) {
                // only creation without update
                return create();
            }
            else if (newConfigurations.length &&
                (configurationIdsToDelete.length || updatedConfigurations.length)) {
                // create and then update
                return create().then(() => {
                    return update();
                });
            }
            else if (
            // only update
            !newConfigurations.length &&
                (configurationIdsToDelete.length || updatedConfigurations.length)) {
                return update();
            }
        };
        // Updating facilities
        const facilitiesUpdate = () => {
            function generateRequestData(arr) {
                return arr.map(el => ({
                    description: el.description,
                    isEnabled: true,
                    isInventoryEnabled: el.inventoryEnabled,
                    isOnDemand: el.is_on_demand || el.onDemand,
                    isPresentInSpace: !el.is_on_demand || !el.onDemand,
                    maxInventory: el.maxInventory,
                    price: el.price || 0,
                    systemServiceId: el.id,
                    vatRate: el.vat,
                    venueServiceId: el.venueServiceId
                }));
            }
            const checked = state.formData.facilities.filter(el => el.selected);
            const previous = state.prevData.facilities.filter(el => el.selected);
            const idsToDelete = previous
                .filter(el => !checked.find(item => el.id === item.id))
                .map(el => el.venueServiceId);
            const newServices = checked.filter(el => state.prevData.facilities.find(item => el.id === item.id && !item.selected));
            const updated = difference(checked, newServices);
            const _input = {
                update: {
                    spaceId: +spaceId,
                    venueServicesToDeleteIds: idsToDelete,
                    venueServices: generateRequestData(updated).map(el => {
                        delete el.systemServiceId;
                        return el;
                    })
                },
                create: {
                    labelId: labelId,
                    spaceId: +spaceId,
                    venueServices: generateRequestData(newServices).map(el => {
                        delete el.venueServiceId;
                        return el;
                    })
                }
            };
            function update() {
                return updateFacilitiesMutation({ input: _input.update }, {});
            }
            function create() {
                return createFacilitiesMutation({ input: _input.create }, {});
            }
            if (newServices.length && !(idsToDelete.length || updated.length)) {
                // only creation without update
                return create();
            }
            else if (newServices.length && (idsToDelete.length || updated.length)) {
                // create and then update
                return create().then(() => {
                    return update();
                });
            }
            else if (
            // only update
            !newServices.length &&
                (idsToDelete.length || updated.length)) {
                return update();
            }
        };
        const removeSpace = () => {
            deleteSpaceMutation({ spaceId: +spaceId }, {}).catch(({ graphQLErrors }) => {
                const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                let toastText;
                let toastType;
                if (graphQLError.error.status_code !== 403) {
                    globalThis.$router.push({
                        name: ROUTE_LIST_NAMES.SPACES
                    });
                    toastText = root.$i18n.t('common.errors.delete.success', {
                        location_type: root.$route.params.locale === 'en' ? 'Space' : 'Ruimte',
                        name: space?.value?.specific_space?.space_name
                    });
                    toastType = 'success';
                }
                else {
                    toastText = root.$i18n.t('common.errors.delete.reject', {
                        location_type: root.$route.params.locale === 'en' ? 'space' : 'ruimte'
                    });
                    toastType = 'danger';
                }
                addToast({
                    text: toastText,
                    type: toastType,
                    dismissAfter: 5000
                });
            });
        };
        const changeActive = () => {
            const _input = { isActive: !space?.value?.specific_space?.is_active };
            updateSpaceWithInput(_input)
                .then(() => {
                addToast({
                    text: root.$t(`common.activation_status.${_input.isActive ? 'active' : 'inactive'}`),
                    type: _input.isActive ? 'success' : 'danger',
                    dismissAfter: 5000
                });
                refetchSpace({ space_id: +spaceId });
                globalThis.$store.dispatch('$_vendor/loadVenuesList');
                if (_input.isActive) {
                    setTimeout(() => (isShareOpen.value = true), 500);
                }
            })
                .catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                    if (graphQLError.error.status_code === 403) {
                        addToast({
                            text: root.$t(`common.errors.${graphQLError.error.key}`),
                            type: 'danger',
                            dismissAfter: 5000
                        });
                    }
                }
            });
        };
        const goToAgendaSync = () => {
            const venueId = space.value?.specific_space?.venue?.venue_id;
            globalThis.$router.push({
                path: useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}${venueId ? `/${venueId}` : ''}`),
                query: {
                    isAgendaModalOpened: true
                }
            });
        };
        const updateSpaceWithInput = (input) => {
            if (input.spaceCategories) {
                input.categories = [];
                Object.values(input.spaceCategories).forEach(el => {
                    input.categories.push({
                        categoryId: el.id || el.categoryId,
                        hasMandatory: true
                    });
                });
                delete input.spaceCategories;
            }
            if (input.bookingLimit === '') {
                input.bookingLimit = 0;
            }
            return updateSpaceMutation({
                spaceId: +spaceId,
                input
            }, {});
        };
        const showActivationConfirmationPopup = () => {
            state.activateConfirmationVisible = true;
        };
        const handleSpaceActivation = () => {
            if (performAccountFlowAction.value) {
                preSaveAction({ type: PRE_SAVE_ACTION_TYPES.SPACE_ACTIVATION });
                saveCurrentPath(root.$route.fullPath.slice(1));
                performAccountFlowAction.value();
                return;
            }
            showActivationConfirmationPopup();
        };
        const updateFunctions = {
            features: featuresUpdate,
            details: detailsUpdate,
            configurations: configurationsUpdate,
            facilities: facilitiesUpdate
        };
        const continuePreSaveAction = () => {
            if (performAccountFlowAction.value) {
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            let preSavedType = localStorage.getItem('preSavedType');
            if (preSavedType === PRE_SAVE_ACTION_TYPES.SPACE_ACTIVATION) {
                showActivationConfirmationPopup();
            }
        };
        const cancelPreSaveSpaceAction = () => {
            hasUnfinishedAction.value = false;
            unfinishedActionType.value = '';
            resetPreSaveAction();
        };
        onMounted(() => {
            const preSavedType = localStorage.getItem('preSavedType');
            if (preSavedType &&
                preSavedType === PRE_SAVE_ACTION_TYPES.SPACE_ACTIVATION) {
                hasUnfinishedAction.value = true;
                unfinishedActionType.value = preSavedType;
            }
        });
        return {
            hasSpaceActivationPermission,
            hasSynchronizedCalendars,
            hasUnfinishedAction,
            isShareOpen,
            space: computed(() => space.value?.specific_space),
            spaceChild,
            state,
            unfinishedActionType,
            PAGES,
            back,
            cancelPreSaveSpaceAction,
            changeActive,
            continuePreSaveAction,
            goToAgendaSync,
            handleSpaceActivation,
            hasChanges,
            isChangesSaved,
            noChanges,
            onCloseSaveFooter,
            onRevertChanges,
            onSaveChanges,
            removeSpace,
            settingsChanged,
            updateSpaceDetails
        };
    }
});
